import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { SmartCardProvider } from '@atlaskit/link-provider';
import { Card } from '@atlaskit/smart-card';
import { Text, Box, Stack, Inline } from '@atlaskit/primitives';

// TODO: this is hardcoded data, once we have the endpoint working we will replace this array
// with actual data returned from the BE
const PLAYLIST_PLACEHOLDERS = [
	'https://hello.atlassian.net/wiki/spaces/~mike/blog/2024/08/01/4017903779/Founder+Update+144+Continuing+our+enterprise+transformation+-+changes+to+Sales+Success',
	'https://hello.atlassian.net/wiki/spaces/~mike/blog/2024/12/19/4595388243/CEO+Update+Changes+to+Atlassian+Board+of+Directors',
	'https://hello.atlassian.net/wiki/spaces/~mike/blog/2024/11/19/4476765344/CEO+update+An+update+to+our+CAO+org',
	'https://hello.atlassian.net/wiki/spaces/~mike/blog/2024/10/31/4398361292/CEO+update+Announcing+our+new+CRO+-+Brian',
];

const i18n = defineMessages({
	playlistTitle: {
		id: 'audio.miniplayer.playlist-title',
		defaultMessage: 'PLAYLIST',
		description: 'heading for playlist section of the audio miniplayer',
	},
});

export const Playlist: FC = () => {
	const { formatMessage } = useIntl();

	return (
		<Box>
			<Box paddingBlock="space.150">
				<Inline alignInline="start">
					<Text size="small" color="color.text.subtlest" weight="semibold" align="start">
						{formatMessage(i18n.playlistTitle)}
					</Text>
				</Inline>
			</Box>
			<SmartCardProvider product="CONFLUENCE">
				<Stack space="space.200">
					{PLAYLIST_PLACEHOLDERS.map((trackUrl) => (
						// TODO: We likely won't be using Cards in the future, so this is a placeholder till we have designs direction
						<Card key={trackUrl} appearance="inline" url={trackUrl} truncateInline />
					))}
				</Stack>
			</SmartCardProvider>
		</Box>
	);
};
