import React, { useState, useEffect, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/core/close';
import ProgressBar from '@atlaskit/progress-bar';
import Spinner from '@atlaskit/spinner';
import { Box, Stack, Flex } from '@atlaskit/primitives';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Card } from '@atlaskit/smart-card';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import { Divider } from '@atlassian/navigation-system/side-nav/menu-section';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';
import { getContentUrl } from '../utils/getContentUrl';

import { MiniplayerControls } from './MiniplayerControls';
import { Playlist } from './Playlist';

const SKIP_SECONDS = 15;
const START_SECONDS = 0;

const i18n = defineMessages({
	closeButtonLabel: {
		id: 'audio.miniplayer.close-label',
		defaultMessage: 'Close',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will stop audio playback and close the player controls',
	},
});

export const Miniplayer = () => {
	const intl = useIntl();
	const [
		{ activeContent, isLoading, isPlaying, audioSrcUrl, playbackSpeed },
		{
			onContentStopped,
			onContentPaused,
			onContentResumed,
			onContentEnded,
			onContentError,
			onContentPlaybackSpeedChanged,
		},
	] = useAudioPlaybackState();
	const audioRef = useRef<HTMLAudioElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [progress, setProgress] = useState(0);
	const percentOnCloseRef = useRef<number>(0);
	const { trackAIResultAction, trackAIResultView } = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const playAudio = async () => {
			const audio = audioRef.current;
			if (audio && audioSrcUrl && audio.src !== audioSrcUrl) {
				audio.src = audioSrcUrl;
				audio.load();
				audio.playbackRate = playbackSpeed;
				await audio.play();
			}
		};

		playAudio().catch((e) => onContentError(e));
	}, [audioSrcUrl, onContentStopped, onContentError, playbackSpeed]);

	useEffect(() => {
		trackAIResultView();

		const audio = audioRef.current;
		const container = containerRef.current;

		const onPaused = () => {
			onContentPaused();
		};

		const onPlayed = () => {
			onContentResumed();
		};

		const updateProgress = () => {
			if (audio && audio.duration) {
				setProgress(audio.currentTime / audio.duration);
			}
		};

		const onEnded = () => {
			setProgress(0);
			onContentEnded();
		};

		if (audio) {
			audio.addEventListener('pause', onPaused);
			audio.addEventListener('play', onPlayed);
			audio.addEventListener('ended', onEnded);
			audio.addEventListener('timeupdate', updateProgress);
		}

		if (container) {
			container.focus(); // Bring focus upon opening miniplayer
		}

		return () => {
			if (audio) {
				audio.removeEventListener('pause', onPaused);
				audio.removeEventListener('play', onPlayed);
				audio.removeEventListener('ended', onEnded);
				audio.addEventListener('timeupdate', updateProgress);
				trackAIResultAction('audioClosed', {
					attributes: { percentComplete: percentOnCloseRef.current },
				});
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sendAnalyticsUIEvent = (
		actionSubjectId: string,
		additionalAttributes?: Record<string, number | string>,
	) => {
		const attributes = {
			audioType: activeContent?.sourceType,
			summaryLocale: intl.locale,
			...additionalAttributes,
		};
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'miniplayer',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId,
				attributes,
			},
		}).fire();
		trackAIResultAction(`${actionSubjectId}Clicked`, { attributes });
	};

	const onInitiateClose = async () => {
		percentOnCloseRef.current = Math.round(progress * 100);
		await onContentStopped();
	};

	const onInitiatePause = () => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPause');
			audioRef.current.pause();
		}
	};

	const onInitiateResume = async () => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPlay');
			await audioRef.current.play();
		}
	};

	const onInitiateSkipBack = () => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioSkipBackward');
			if (audio.currentTime <= SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
			} else {
				audio.currentTime -= SKIP_SECONDS;
			}
		}
	};

	const onInitiateSkipForward = () => {
		const audio = audioRef.current;
		if (audio && audio.duration) {
			sendAnalyticsUIEvent('audioSkipForward');
			if (audio.currentTime >= audio.duration - SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
				setTimeout(() => audio.pause(), 100); // Small delay to ensure current time is reset and rendered before pausing
			} else {
				audio.currentTime += SKIP_SECONDS;
			}
		}
	};

	const onInitiateChangePlaybackSpeed = (newSpeed: number) => {
		if (audioRef.current) {
			onContentPlaybackSpeedChanged(newSpeed);
			sendAnalyticsUIEvent('audioPlayBackSpeed', { playbackSpeed: newSpeed });
			audioRef.current.playbackRate = newSpeed;
		}
	};

	const onInitiatePlayFromBeginning = async () => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioPlayFromBeginning');
			audio.currentTime = START_SECONDS;
			if (audio.paused) {
				await audio.play();
			}
		}
	};

	type CallbackType = (() => any) | (() => Promise<any>);
	const tryCallback = async (callback: CallbackType) => {
		try {
			await callback();
		} catch (e) {
			onContentError(e);
		}
	};

	const contentUrl = useMemo(() => {
		return !isLoading && activeContent ? getContentUrl(activeContent) : null;
	}, [activeContent, isLoading]);

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
		<Box paddingBlock="space.150" paddingInline="space.150" tabIndex={0} ref={containerRef}>
			<Box>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption -- The summary is already available in text so the track isn't very necessary */}
				<audio data-testid="miniplayer-audio" ref={audioRef} autoPlay />
				<Stack space="space.150">
					<Flex columnGap="space.100" justifyContent="space-between">
						<Box>
							{contentUrl && (
								<SmartCardProvider product="CONFLUENCE">
									<Card appearance="inline" url={contentUrl} truncateInline />
								</SmartCardProvider>
							)}
						</Box>
						<Box>
							<IconButton
								icon={CloseIcon}
								label={intl.formatMessage(i18n.closeButtonLabel)}
								tooltip={{ content: intl.formatMessage(i18n.closeButtonLabel) }}
								isTooltipDisabled={false}
								appearance="subtle"
								spacing="compact"
								shape="circle"
								testId="miniplayer-close"
								onClick={() => tryCallback(onInitiateClose)}
							/>
						</Box>
					</Flex>
					{isLoading && (
						<Box paddingBlockEnd="space.200" testId="miniplayer-loading">
							<Spinner size="large" />
						</Box>
					)}
					{!isLoading && (
						<>
							<MiniplayerControls
								isPlaying={isPlaying}
								onPause={() => tryCallback(onInitiatePause)}
								onResume={() => tryCallback(onInitiateResume)}
								onSkipBack={() => tryCallback(onInitiateSkipBack)}
								onSkipForward={() => tryCallback(onInitiateSkipForward)}
								onChangePlaybackSpeed={(speed) =>
									tryCallback(onInitiateChangePlaybackSpeed.bind(this, speed))
								}
								onPlayFromBeginning={() => tryCallback(onInitiatePlayFromBeginning)}
							/>
							<Box>
								<ProgressBar value={progress} />
							</Box>
						</>
					)}
				</Stack>
			</Box>
			<Divider />
			<Playlist />
		</Box>
	);
};
