import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';

import { MiniplayerPlaybackSpeedControl } from './MiniplayerPlaybackSpeedControl';

const i18n = defineMessages({
	moreButtonLabel: {
		id: 'audio.miniplayer-controls.more-label',
		defaultMessage: 'More',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will open additional playback controls for the active audio',
	},
});

export type MiniplayerMoreOptionsMenuProps = {
	onChangePlaybackSpeed: (newSpeed: number) => void;
};

export const MiniplayerMoreOptionsMenu: FC<MiniplayerMoreOptionsMenuProps> = ({
	onChangePlaybackSpeed,
}) => {
	const intl = useIntl();

	return (
		<DropdownMenu<HTMLButtonElement>
			trigger={({ triggerRef, ...props }) => (
				<IconButton
					{...props}
					icon={ShowMoreHorizontalIcon}
					label={intl.formatMessage(i18n.moreButtonLabel)}
					tooltip={{ content: intl.formatMessage(i18n.moreButtonLabel) }}
					isTooltipDisabled={false}
					appearance="subtle"
					shape="circle"
					testId="miniplayer-more-controls"
					ref={triggerRef}
				/>
			)}
			shouldRenderToParent
			placement="bottom-end"
		>
			<DropdownItemGroup>
				<MiniplayerPlaybackSpeedControl onChangePlaybackSpeed={onChangePlaybackSpeed} />
			</DropdownItemGroup>
		</DropdownMenu>
	);
};
